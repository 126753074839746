<template>
  <div class="layoutDefault" style="margin-top: 2rem">
    <div class="grid grid-cols-1 gap-4">
      <div class="z-10">
        <div
          class="flex cursor-pointer rounded-lg py-2"
          style="max-width: 200px"
          @click="$router.go(-1)"
        >
          <IconArrow
            class="mr-3 mt-1 stroke-current text-habu h-6 w-6 stroke-2 transform rotate-0"
          />
          <span class="text-2xl text-normal">Open Ticket</span>
        </div>
      </div>
      <!-- ... -->
      <div class="z-10 px-2" v-if="loadData">
        <div class="grid sm:grid-cols-5 gap-5">
          <div class="relative sm:col-span-2">
            <div class="card-ticket-form-content rounded-7px pb-5">
              <div class="absolute top-22 w-full" style="border-top: 1px dashed #d7d7d7"></div>

              <div class="grid px-3">
                <div class="flex space-x-3 mx-auto mt-6">
                  <div class="flex-initial">
                    <IconTicket />
                  </div>
                  <div>
                    <div class="font-semibold text-htext">Open Ticket</div>
                    <div class="text-xs text-htext">Open ticket with selected instrument</div>
                  </div>
                </div>
              </div>
              <div class="grid gap-3 px-5 mt-12">
                <div class="relative">
                  <div class="font-semibold text-sm text-htext">Select Ticket Type</div>
                  <div class="inline-flex whitespace-nowrap" v-if="v$.form.ticket_type_id.$error">
                    <img :src="SvgWarning" class="mr-2" />
                    <span
                      class="text-red-500 italic"
                      style="font-size: 14px; line-height: 1; font-weight: 300"
                    >
                      Please select the ticket type first!
                    </span>
                  </div>
                </div>
                <div v-if="data.instrument" class="grid gap-3">
                  <div
                    class="py-4 px-5 hb-tt flex justify-between relative cursor-pointer"
                    v-for="m in ticketTypes.technical"
                    :key="m.id"
                    :class="m.id == form.ticket_type_id ? 'bg-hblue' : 'bg-white'"
                    @click="setTT(m.id)"
                  >
                    <div class="flex space-x-3">
                      <TicketRequest v-if="m.id == 2" class="mt-1" />
                      <TicketTechnical v-if="m.id == 1" class="mt-1" />

                      <p
                        class="mt-1.2 mb-1"
                        :class="
                          m.id == form.ticket_type_id ? 'text-white font-semibold' : 'text-htext'
                        "
                      >
                        {{ m.alias }}
                      </p>
                    </div>

                    <IA
                      class="absolute right-0 inset-y-auto mt-2.5 mr-1 h-5 w-5 stroke-current justify-self-end"
                      :class="m.id == form.ticket_type_id ? 'text-white' : 'text-htext'"
                    />
                  </div>
                </div>
                <div v-else class="mx-auto">
                  <IconOops class="h-24 w-41" />
                  <div class="text-center text-xl font-Roboto font-bold text-htext">404</div>
                  <div class="text-center font-Roboto text-htext tracking-widest">
                    Ticket type not found
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-ticket-form-content relative sm:col-span-3 rounded-7px">
            <div class="absolute top-22 w-full" style="border-top: 1px dashed #d7d7d7"></div>
            <div class="grid max-w-lg mx-auto">
              <div class="sm:col-span-7 xs:mt-8">
                <div class="text-xs text-htext">Instrument</div>
                <div class="font-semibold text-htext mb-9">
                  {{ data.instrument ? data.instrument.instrument_type.name : '#' }} -
                  {{ data.instrument ? data.instrument.serial_number : '#' }}
                </div>
              </div>
              <div class="sm:col-span-7" v-if="data.instrument">
                <div class="form-control">
                  <label class="label">
                    <span class="label-text font-semibold text-htext">Division</span>
                    <div class="inline-flex whitespace-nowrap" v-if="v$.form.division_id.$error">
                      <img :src="SvgWarning" class="mr-2" />
                      <span
                        class="text-red-500 italic"
                        style="font-size: 14px; line-height: 1; font-weight: 300"
                      >
                        Please select the request type first!
                      </span>
                    </div>
                  </label>
                  <div class="grid sm:grid-cols-2 gap-3 justify-between relative cursor-pointer">
                    <div
                      class="hb-tt py-3 px-5"
                      v-for="m in data.divisions"
                      :key="m.id"
                      @click="setDivision(m.id)"
                      :class="m.id == form.division_id ? 'bg-hblue' : 'bg-white'"
                    >
                      <p
                        class="mt-1.2 mb-1 text-center"
                        :class="
                          m.id == form.division_id ? 'text-white font-semibold' : 'text-htext'
                        "
                      >
                        {{ m.name }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="form-control" v-if="form.ticket_type_id == 2">
                  <label class="label">
                    <span class="label-text font-semibold text-htext">Request Type</span>
                    <div class="inline-flex whitespace-nowrap" v-if="v$.form.request_id.$error">
                      <img :src="SvgWarning" class="mr-2" />
                      <span
                        class="text-red-500 italic"
                        style="font-size: 14px; line-height: 1; font-weight: 300"
                      >
                        Please select the request type first!
                      </span>
                    </div>
                  </label>
                  <treeselect
                    ref="req-type"
                    class="h-9"
                    :multiple="false"
                    v-model="form.request_id"
                    :options="listRequestType"
                  />
                </div>

                <div class="form-control">
                  <label class="label">
                    <span class="label-text font-semibold text-htext">Note</span>
                    <div class="inline-flex whitespace-nowrap" v-if="v$.form.description.$error">
                      <img :src="SvgWarning" class="mr-2" />
                      <span
                        class="text-red-500 italic"
                        style="font-size: 14px; line-height: 1; font-weight: 300"
                      >
                        Note is required!
                      </span>
                    </div>
                  </label>
                  <textarea
                    class="textarea h-24 textarea-bordered placeholder"
                    placeholder="Type note here..."
                    v-model="form.description"
                  ></textarea>
                </div>

                <div class="form-control">
                  <label class="label">
                    <span class="label-text font-semibold text-htext">Contact person</span>
                    <div class="inline-flex whitespace-nowrap" v-if="v$.form.staff_name.$error">
                      <img :src="SvgWarning" class="mr-2" />
                      <span
                        class="text-red-500 italic"
                        style="font-size: 14px; line-height: 1; font-weight: 300"
                      >
                        Please enter contact person first!
                      </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    v-model="form.staff_name"
                    placeholder="Type contact person here..."
                    class="input input-bordered transition rounded-lg w-full py-2 px-3 h-9 focus:ring-transparent"
                  />
                </div>
                <div class="form-control">
                  <label class="label">
                    <span class="label-text font-semibold text-htext">Phone number</span>
                    <div
                      class="inline-flex whitespace-nowrap"
                      v-if="v$.form.staff_phone_number.$error"
                    >
                      <img :src="SvgWarning" class="mr-2" />
                      <span
                        class="text-red-500 italic"
                        style="font-size: 14px; line-height: 1; font-weight: 300"
                      >
                        Please enter phone number first!
                      </span>
                    </div>
                  </label>
                  <input
                    type="number"
                    v-model="form.staff_phone_number"
                    placeholder="Type phone number here..."
                    class="input input-bordered transition rounded-lg w-full py-2 px-3 h-9 focus:ring-transparent"
                  />
                </div>

                <div
                  style="width: 100%; height: 14px; border-bottom: 2px solid #363636"
                  class="text-center mt-3"
                >
                  <span class="bg-white text-sm font-bold" style="padding: 0 7px"> Priority </span>
                </div>

                <div class="flex space-x-2 justify-between overflow-x-auto mt-4">
                  <div
                    class="pt-2.5 relative cursor-pointer flex-auto"
                    v-for="p in priorities"
                    :key="p"
                    :class="
                      p == form.priority ? `act-btn-prio bg-h${form.priority}` : 'list-btn-prio'
                    "
                    @click="setPrio(p)"
                  >
                    <p
                      class="font-bold text-sm text-center capitalize"
                      :class="p == form.priority ? `text-htext${form.priority}` : 'text-black'"
                    >
                      {{ p }}
                    </p>
                  </div>
                </div>

                <div class="mt-8 mb-4 text-center w-full">
                  <button
                    class="btn btn-primary h-9.3 mx-auto capitalize border-0 hover:bg-hblue"
                    @click="submit"
                  >
                    Create ticket
                  </button>
                </div>
              </div>

              <div v-else class="my-9">
                <IconOops class="mx-auto h-36 w-47" />
                <div class="text-center text-xl font-Roboto font-bold text-htext">404</div>
                <div class="text-center font-Roboto text-htext tracking-widest">
                  Sorry, instrument not found, please contact the support team for this issue.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="z-10" v-else>
        <div class="card-ticket-form animate-pulse">
          <div class="text-2xl text-medium pt-3 pb-0 px-5 text-black">
            <div class="h-7 bg-hline rounded w-68 mt-3"></div>
          </div>

          <div class="grid grid-cols-3 gap-4" style="width: 100%">
            <div
              class="group relative mt-3 pt-1 pb-0.5 px-5 text-center z-10 cursor-pointer hover:bg-habu2"
              v-for="m in 3"
              :key="m"
            >
              <div class="h-9 bg-hline rounded"></div>
            </div>
          </div>
          <div class="card-ticket-form-content mt-2 px-4 pt-8 pb-5">
            <div class="open-technical">
              <div class="grid xs:grid-cols-1 sm:grid-cols-12 gap-2">
                <div class="xs:col-span-12 sm:col-span-4">
                  <div class="mx-auto text-center" style="max-width: 180px">
                    <div class="text-2xl text-black">
                      <div class="h-10 bg-hline rounded"></div>
                    </div>

                    <div
                      class="mt-4 pt-3 mx-auto relative cursor-pointer hover:bg-habu2"
                      v-for="d in 5"
                      :key="d"
                    >
                      <div class="h-9 bg-hline rounded"></div>
                    </div>
                  </div>
                </div>
                <div class="col-span-1 xs:invisible sm:visible pt-18">
                  <div
                    class="mx-auto rounded-lg"
                    style="border: 4px solid #eeeeee; min-height: 375px; width: 0px"
                  ></div>
                </div>
                <div class="xs:col-span-12 sm:col-span-7" style="width: 400px; margin: 0 auto">
                  <div class="text-2xl text-black mb-7">
                    <div class="h-9 bg-hline rounded-lg"></div>
                  </div>

                  <div class="form-control mb-5">
                    <div class="h-16 bg-hline rounded-lg"></div>
                  </div>
                  <div class="form-control mb-5">
                    <div class="h-8 bg-hline rounded"></div>
                  </div>
                  <div class="form-control mb-5">
                    <div class="h-8 bg-hline rounded"></div>
                  </div>

                  <div
                    style="width: 100%; height: 14px; border-bottom: 2px solid #363636"
                    class="text-center mb-10"
                  >
                    <div class="h-8 bg-hline rounded"></div>
                  </div>

                  <div class="grid grid-cols-3 gap-2">
                    <div v-for="p in 3" :key="p">
                      <div class="h-8 bg-hline rounded"></div>
                    </div>
                  </div>

                  <div class="mt-8 mb-4 text-center w-full">
                    <div class="h-10 bg-hline rounded mx-auto"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrow from '@/assets/icons/icon-arrow.svg?inline'
import IA from '@/assets/icons/icon-arrow-nav.svg?inline'
import IconTicket from '@/assets/icons/icon-openTicket.svg?inline'
import TicketTechnical from '@/assets/icons/icon-technical.svg?inline'
import TicketRequest from '@/assets/icons/icon-nRequest.svg?inline'
import IconOops from '@/assets/icons/icon-oops.svg?inline'

import group357 from '@/assets/images/group357.png'
import SvgWarning from '@/assets/icons/icon-warning.svg'

import { mapActions, mapState, mapGetters } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import Treeselect from '@riophae/vue-treeselect'

export default {
  name: 'ticket-open-qr',
  components: {
    IconArrow,
    Treeselect,
    IconTicket,
    IA,
    TicketRequest,
    TicketTechnical,
    IconOops
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      loadData: false,
      group357,
      SvgWarning,
      form: {
        instrument_id: null,
        description: '',
        staff_name: '',
        staff_phone_number: '',
        division_id: null,
        priority: 'low',
        request_id: null,
        ticket_type_id: null
      },
      priorities: ['low', 'medium', 'high'],
      divLoading: false,
      data: {}
    }
  },
  validations() {
    return {
      form: {
        description: { required },
        staff_name: { required },
        staff_phone_number: { required },
        division_id: { required },
        priority: { required },
        request_id: {
          required: requiredIf(function () {
            return this.form.ticket_type_id == 1 ? false : true
          })
        },
        ticket_type_id: { required }
      }
    }
  },
  computed: {
    ...mapGetters('ticket', ['ticketTypes']),
    ...mapState({
      divisions: state => state.ticket.divisions
    }),
    ...mapGetters('ticket', ['listInstrument', 'listRequestType'])
  },
  mounted() {
    this.setData()
  },
  methods: {
    ...mapActions('modal', ['MODAL_TICKET_TYPE', 'MODAL_CONFIRM_NEW_TICKET', 'MODAL_ERROR']),
    ...mapActions('ticket', [
      'PRE_OPEN_TICKET',
      'TICKET_FORM',
      'GET_REQUEST_TYPE',
      'GET_DIVISIONS',
      'GET_INSTRUMENTS'
    ]),
    ...mapActions('instrument', ['GET_INSTRUMENT_QR']),
    async setData() {
      let div = this.$route.query

      if (div.instrument_id) this.form.instrument_id = div.instrument_id

      let res = await this.GET_INSTRUMENT_QR({
        instrument_id: div.instrument_id
      })

      if (res) this.data = res

      this.GET_DIVISIONS()

      setTimeout(() => (this.loadData = true), 1000)
    },
    setTT(val) {
      this.form.ticket_type_id = val
      this.form.request_id = null
    },
    async setDivision(val) {
      let id = val ? val : null
      let res = false

      if (!this.divLoading) {
        this.divLoading = true
        if (id) res = await this.PRE_OPEN_TICKET({ division_id: id })

        this.divLoading = false
      }

      if (res) {
        this.GET_REQUEST_TYPE(id)
        this.form.division_id = val
        this.GET_INSTRUMENTS({
          division_id: id
        })
      } else this.MODAL_ERROR(true)
    },
    setPrio(val) {
      this.form.priority = val
    },
    async submit() {
      const result = await this.v$.$validate()
      if (!result) {
        return
      }

      await this.TICKET_FORM(this.form)

      this.MODAL_CONFIRM_NEW_TICKET(true)
    }
  }
}
</script>
